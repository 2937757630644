import './App.css';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import { Canvas, extend, useFrame, useLoader, useThree } from 'react-three-fiber';
import circleImg from './assets/circle.png';
import solanalogo from './assets/solana_logo.png';

import phantomlogo from './assets/phantom_logo.png';
import logo1 from './assets/Logo.png';
import icon from './assets/UC_App_Icon_Small.png';
import factoryicon from './assets/UC_Factory_Icon_Small.png';
import legacyicon from './assets/UC_Legacy_Icon_Small.png';


import { Suspense, useCallback, useMemo, useRef } from 'react';
extend({OrbitControls})

function CameraControls(){
  const {
    camera,
    gl: {domElement}
  } = useThree();

  const controlsRef = useRef();
  useFrame(() => controlsRef.current.update())

  return (
    <orbitControls
      ref={controlsRef}
      args={[camera, domElement]}
      autoRotate
      autoRotateSpeed={-1}
    />
  );
}

function Points() {
  const imgTex = useLoader(THREE.TextureLoader, circleImg);
  const bufferRef = useRef();

  let t = 0;
  let f = 0.002;
  let a = 3;
  const graph = useCallback((x, z) => {
    return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
  }, [t, f, a])

  const count = 100
  const sep = 3
  let positions = useMemo(() => {
    let positions = []

    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);
        let y = graph(x, z);
        positions.push(x, y, z);
      }
    }

    return new Float32Array(positions);
  }, [count, sep, graph])

  useFrame(() => {
    t += 15

    const positions = bufferRef.current.array;

    let i = 0;
    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);

        positions[i + 1] = graph(x, z);
        i += 3;
      }
    }

    bufferRef.current.needsUpdate = true;
  })

  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={bufferRef}
          attachObject={['attributes', 'position']}
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>

      <pointsMaterial
        attach="material"
        map={imgTex}
        color={0x990000}
        size={0.5}
        sizeAttenuation
        transparent={false}
        alphaTest={0.5}
        opacity={1.0}
      />
    </points>
  );
}



function LogoInsert() {


  return (
    <div>
    <div id="logo2">
        <img className="logo_insert" alt="Undercurrents Logo" width="75%" height="auto" src={logo1} />
    </div>
    <div id="solana_logo">
      <img  className="sol_logo"  alt="Solana" height="100" src={solanalogo} />
    </div>
      <a  href="http://nft.undercurrents.com/factory">
    <div id="factorylogo">
    <img alt="Factory" className="sol_logo"  height="135" src={factoryicon}  />
    </div></a>
    <div id="uc_icon">
      <a href="http://undercurrents.app"><img alt="Undercurrents App"  height="125" src={icon} /></a>
    </div>
    <div id="phtm_logo">
      <a href="http://phantom.app"><img alt="Phantom" className="ftx_logo" height="100" src={phantomlogo} /></a>
    </div>

    </div>
  );
}



function AnimationCanvas() {
  return (
    <Canvas
      colorManagement={false}
      camera={{ position: [35, 25, 0], fov: 75 }}
    >
      <Suspense fallback={null}>
        <Points />
      </Suspense>
      <CameraControls/>
    </Canvas>
  );
}



function App() {
  return (
    <div className="anim">
      <Suspense fallback={<div>Loading...</div>}>
        <AnimationCanvas />
      </Suspense>
      <LogoInsert />
    </div>




  );
}

export default App;
